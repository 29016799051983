import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import devicePNG from '../../../static/img/projects/device.png';
import logoPNG from '../../../static/img/projects/svg/R360-projects-appicon.svg';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { MainTitle, TitleWrapper } from './CommonElement';
import Nl2Br from './Nl2Br';
import SharedCss from './SharedCss';

const WhatsWrapper = styled.div`
  position: relative;
  padding: 230px 30px 50px;
  margin-top: 20px;
  background-image: url(/img/projects/bg-logo.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 70%;

  /** Common Css **/
  ${SharedCss}

  /* PC */
  @media (min-width: 1024px) {
    padding: 100px 30px 100px;
    margin-top: 0;
    background-position: calc(50% - 440px) 80px;
    background-size: 600px;
  }
`;

const WhatsDisc = styled.div`
  body & {
    margin-bottom: 40px;

    /* PC */
    @media (min-width: 1024px) {
      margin-bottom: 160px;
    }
  }
`;

const WhatsDeviceImage = styled.img`
  body & {
    position: absolute;
    top: 60px;
    left: 50%;
    width: 60px;
    margin-left: -80px;

    /* PC */
    @media (min-width: 1024px) {
      top: 250px;
      left: 50%;
      width: 90px;
      margin-left: -480px;
    }
  }
`;

const WhatsLogoImage = styled.img`
  body & {
    position: absolute;
    top: 140px;
    left: 50%;
    width: 60px;
    margin-left: 20px;

    /* PC */
    @media (min-width: 1024px) {
      top: 340px;
      left: 50%;
      width: 90px;
      margin-left: 360px;
    }
  }
`;

const WhatsTitle = styled(TitleWrapper)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      margin-bottom: 60px;
    }
  }
`;

const WhatsTitleJa = styled(MainTitle)`
  body & {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 1px;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }
`;

const WhatsDiscTitle = styled.h3`
  body & {
    font-size: 18px;
    font-weight: 300;
    color: #0093bb;
    text-align: center;

    /* PC */
    @media (min-width: 1024px) {
      margin-bottom: 30px;
      font-size: 34px;
      line-height: 1.4;
    }
  }
`;

const WhatsDiscDetail = styled.p`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      width: 600px;
      margin: 0 auto;
    }
  }
`;

const WhatsFlowWrapper = styled.div`
  body & {
    padding: 40px 20px;
    text-align: center;
    background: #e5f4f8;

    /* PC */
    @media (min-width: 1024px) {
      max-width: 1024px;
      height: 500px;
      padding: 50px 60px 30px;
      margin: 0 auto;
    }
  }
`;

const WhatsFlowTitle = styled.h3`
  body & {
    margin-bottom: 40px;
    font-size: 14px;
    font-weight: 300;
    color: #0093bb;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 30px;
    }
  }
`;

const WhatsFlowList = styled.ul`
  body & {
    padding: 0 40px;

    /* TAB */
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-height: 200px;
      padding: 0;
    }

    /* PC */
    @media (min-width: 1024px) {
      justify-content: flex-start;
    }
  }
`;

const WhatsFlowListItem = styled.li`
  body & {
    padding: 10px;
    margin-bottom: 20px;
    background: #fff;

    &:nth-child(2) {
      position: relative;
      margin-bottom: 60px;
    }

    &:nth-child(2)::after {
      position: absolute;
      right: 30px;
      bottom: -50px;
      display: block;
      width: 60px;
      height: 40px;
      content: '';
      background-image: url(/img/projects/svg/ico-cloud.svg);
      background-repeat: no-repeat;
      background-size: 60px;
    }

    &:nth-child(2)::before {
      position: absolute;
      right: 50%;
      bottom: -45px;
      display: block;
      width: 60px;
      height: 30px;
      margin-left: -10px;
      content: '';
      background-image: url(/img/projects/svg/ico-arrow-01.svg);
      background-repeat: no-repeat;
      background-size: 60px;
      transform: rotate(90deg);
    }

    /* TAB */
    @media (min-width: 768px) {
      width: 31%;
      padding: 20px;
      margin-bottom: 0;

      &:nth-child(2)::after {
        top: 50%;
        right: -40px;
        margin-top: -35px;
      }

      &:nth-child(2)::before {
        top: 50%;
        right: -40px;
        margin-top: 10px;
        margin-left: -30px;
        transform: rotate(0);
      }
    }

    /* PC */
    @media (min-width: 1024px) {
      padding: 40px 0 20px;

      &:first-of-type {
        margin-right: 15px;
      }

      &:nth-child(2) {
        margin-right: 30px;
      }
    }
  }
`;

const WhatsFlowItemTitle = styled.h4`
  body & {
    font-weight: 300;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }
`;

const WOS_DATA_QUERY = graphql`
  query WhatData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-what" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            whatData {
              whatText1
              whatText2
              whatText3
              whatText4
              whatItem1
              whatItem2
              whatItem3
            }
          }
        }
      }
    }
  }
`;

const What: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.WhatDataQuery>(
    WOS_DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const whatData = frontmatter?.whatData;

  return (
    <WhatsWrapper>
      <WhatsDisc>
        <WhatsDeviceImage src={devicePNG} alt="device" />
        <WhatsLogoImage src={logoPNG} alt="logo" />
        <WhatsTitle>
          <WhatsTitleJa>{whatData?.whatText1}</WhatsTitleJa>
        </WhatsTitle>
        <WhatsDiscTitle>
          <Nl2Br text={whatData?.whatText2} />
        </WhatsDiscTitle>
        <WhatsDiscDetail>{whatData?.whatText3}</WhatsDiscDetail>
      </WhatsDisc>
      <WhatsFlowWrapper>
        <WhatsFlowTitle>
          <Nl2Br text={whatData?.whatText4} />
        </WhatsFlowTitle>
        <WhatsFlowList>
          <WhatsFlowListItem>
            <StaticImage
              src="../../../static/img/projects/flow-01.jpeg"
              alt="photography"
            />
            <WhatsFlowItemTitle>{whatData?.whatItem1}</WhatsFlowItemTitle>
          </WhatsFlowListItem>
          <WhatsFlowListItem>
            <StaticImage
              src="../../../static/img/projects/flow-02.jpeg"
              alt="organize"
            />
            <WhatsFlowItemTitle>{whatData?.whatItem2}</WhatsFlowItemTitle>
          </WhatsFlowListItem>
          <WhatsFlowListItem>
            <StaticImage
              src="../../../static/img/projects/flow-03.png"
              alt="share"
            />
            <WhatsFlowItemTitle>{whatData?.whatItem3}</WhatsFlowItemTitle>
          </WhatsFlowListItem>
        </WhatsFlowList>
      </WhatsFlowWrapper>
    </WhatsWrapper>
  );
};

export default What;
